import React from "react"
import "src/styles/index.scss"
import maarsLogo from "../images/maars_logo_neon.svg"
import {
  SEO,
  Footer,
  AnnouncementModal,
  Background,
  Navigation,
} from "components"

export const query = graphql`
  query HomePageQuery {
    prismicInfoModal {
      data {
        title {
          text
        }
        body_copy {
          text
        }
        optional_link {
          url
        }
        link_text
      }
    }
    prismicHomePage {
      data {
        facebook_link {
          url
        }
        google_maps_link {
          url
        }
        instagram_link {
          url
        }
        online_store_link {
          url
        }
      }
    }
  }
`

const parseHomePageData = data => {
  const facebookUrl = data.facebook_link && data.facebook_link.url
  const instagramUrl = data.instagram_link && data.instagram_link.url
  const googleMapsUrl = data.google_maps_link && data.google_maps_link.url
  const onlineStoreUrl = data.online_store_link && data.online_store_link.url
  return {
    facebookUrl,
    instagramUrl,
    googleMapsUrl,
    onlineStoreUrl,
  }
}

const parseModalData = data => {
  const modalTitle = data && data.title && data.title.text
  const modalBodyCopy = data && data.body_copy && data.body_copy.text
  const modalLink = data && data.optional_link && data.optional_link.url
  const modalLinkText = data && data.link_text
  return {
    modalTitle,
    modalBodyCopy,
    modalLink,
    modalLinkText,
  }
}
const IndexPage = ({ data }) => {
  const prismicInfoModal = data.prismicInfoModal && data.prismicInfoModal.data
  const prismicHomePage = data.prismicHomePage && data.prismicHomePage.data

  const {
    facebookUrl,
    instagramUrl,
    googleMapsUrl,
    onlineStoreUrl,
  } = parseHomePageData(prismicHomePage)

  const {
    modalTitle,
    modalBodyCopy,
    modalLink,
    modalLinkText,
  } = parseModalData(prismicInfoModal)

  return (
    <div className="maars-main">
      {prismicInfoModal && prismicInfoModal.title && (
        <AnnouncementModal
          title={modalTitle}
          message={modalBodyCopy}
          linkText={modalLinkText}
          link={modalLink}
        />
      )}
      <SEO />
      <Background containerClassName="maars-content-container">
        <img
          className="maars-logo"
          src={maarsLogo}
          alt="The Maar's Burger Bar Logo"
        ></img>
        <Navigation onlineStoreUrl={onlineStoreUrl} />
        <Footer
          facebookUrl={facebookUrl}
          instagramUrl={instagramUrl}
          googleMapsUrl={googleMapsUrl}
        />
      </Background>
    </div>
  )
}

export default IndexPage
